import ServicesCards from './servicesCards';
import TwoColumnCard from './twoColumnCard';
import TextAndSubtitle from './textAndSubtitle';
import { Container } from '@mui/material';
import Circuits from "./animated_icons/circuits";

function MicrosoftImage(){
  return(
    <img src="Microsoft_Logo.png" alt="Microsoft" style={{width:"100px"}} />
  )
}

function AWSImage(){
  return(
    <img src="AWS_Logo.png" alt="AWS" style={{width:"100px"}} />
  )
}

function BSIImage(){
  return(
    <img src="BSI_Logo.png" alt="BSI" style={{width:"100px"}} />
  )
}

function ScotISImage(){
  return(
    <img src="ScotlandIS_logo.png" alt="ScotlandIS" style={{width:"100px"}} />
  )
}
function BBAImage(){
  return(
    <img src="bba_logo.png" alt="BBA" style={{width:"100px"}} />
  )
}

function PCSImage(){
  return(
    <img src="PCS_Logo.png" alt="PCS" style={{width:"100px"}} />
  )
}

function Partnerships() {

  const items = [
    { icon: <MicrosoftImage/>, title: 'Microsoft', description:'Data & AI Solutions Partner' },
    { icon: <AWSImage/>, title: 'Amazon Web Services', description:'Startup Member' },
    { icon: <BSIImage/>, title: 'The British Standards Institution', description:'Member' },
    { icon: <PCSImage/>, title: 'Public Contracts Scotland', description:'Member' },
    { icon: <ScotISImage/>, title: 'ScotlandIS', description:'Startup Member' },
    { icon: <BBAImage/>, title: 'Better Business Act', description:'Member' }
  ];

    return (
      <>
        <Container id="partnerships">
          <TwoColumnCard  
            left_item={<TextAndSubtitle subtitle={'We collaborate closely with top industry leaders in technology and adhere to the highest standards to ensure we deliver best-in-class solutions. By leveraging the expertise of trusted partners and staying at the forefront of innovation, we are committed to offering cutting-edge products and services that not only meet but exceed industry benchmarks.'} title={'Leverage our wide variety of'} highlighted={'Partnerships.'} ></TextAndSubtitle>}
            right_item={<ServicesCards items={items}/>}
          />
        </Container>
      </>
    );
  }
  export default Partnerships;